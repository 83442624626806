<template>
  <form>
    <v-row>
      <v-col cols="12" sm="12" md="11">
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>First Name <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" class="py-0" align-self="center">
                <v-text-field dense outlined flat v-model="form.firstName" v-if="!edit" />
                <span v-if="edit">{{ form.firstName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>Last Name</v-subheader>
              </v-col>
              <v-col cols="8" class="py-0" align-self="center">
                <v-text-field dense outlined flat v-model="form.lastName" v-if="!edit" />
                <span v-if="edit">{{ form.lastName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>Email <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" class="py-0" align-self="center">
                <v-text-field dense outlined flat v-model="form.email" v-if="!edit" />
                <span v-if="edit">{{ form.email }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>Mobile</v-subheader>
              </v-col>
              <v-col cols="8" class="py-0" align-self="center">
                <v-text-field dense outlined flat v-model="form.mobile" v-if="!edit" />
                <span v-if="edit">{{ form.mobile }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="4" class="py-0">
                <v-subheader>Role <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" class="py-0" align-self="center">
                <v-select dense outlined flat v-model="form.role" :items="roles" v-if="!edit" />
                <span v-if="edit">{{ form.role }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateUser">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editForm">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="deleteUser">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "user-detail",
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    roles: [
      { value: "admin", text: "ROLE_ADMIN" },
      { value: "manager", text: "ROLE_MANAGER" },
      { value: "user", text: "ROLE_USER" },
      { value: "finance", text: "ROLE_FINANCE" },
      { value: "accounting", text: "ROLE_ACCOUNTING" },
      { value: "director", text: "ROLE_DIRECTOR" },
    ],
  }),
  computed: {
    ...mapState("user", ["user"]),
    form() {
      return Object.assign({}, this.user);
    },
  },
  methods: {
    getUserDetail() {
      this.$store.dispatch("user/getById", this.id);
    },
    updateUser() {
      if (typeof this.form.role === "object") {
        if (this.form.role.id === 1) {
          this.form.role = "admin";
        } else if (this.form.role.id === 2) {
          this.form.role = "manager";
        } else {
          this.form.role = "user";
        }
      }
      this.$store.dispatch("user/update", this.form);
    },
    deleteUser() {
      this.$store.dispatch("user/delete", this.id);
    },
    back() {
      this.$router.go(-1);
    },
    editForm() {
      this.edit = false;
    },
    cancel() {
      this.edit = true;
    },
  },
  mounted() {
    this.getUserDetail();
  },
};
</script>

<style></style>
